import { Component, OnDestroy, OnInit } from '@angular/core';
import { IniciarService } from '../../api/iniciar.service';
import { IUsuarioLogin } from '../../type';
import { Subscription, interval } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sesion-usuario',
  templateUrl: './usuario.component.html',
  styleUrls: ['./usuario.component.scss'],
})
export class SesionUsuarioComponent implements OnInit, OnDestroy {
  user!: IUsuarioLogin | null;
  suscrito!: Subscription;

  constructor(private sesionService: IniciarService, private router: Router) {
  }

  ngOnInit(): void {
    if (this.sesionService.isAuth()) {
      this.sessionAuth();
    }
  }

  logout(): void {
    this.sesionService.unauth();
    this.user = null;
  }

  sessionAuth(): void {
    this.user = null;

    if (this.sesionService.isAuth()) {
      this.sesionService.current().subscribe({
        next: (data) => {
          this.user = data;
        },
        error: (error) => {
          console.error('error', error.error);
        }
      })

      this.suscrito = interval(1000).subscribe(e => {
        if (!this.sesionService.isAuth()) {
          this.sesionService.unauth()
          this.router.navigateByUrl('/sesion/login')
            .then(e => e);
        }
      });
    }
  }

  ngOnDestroy(): void {
    if (this.suscrito) {
      this.suscrito.unsubscribe()
    }
  }

}
