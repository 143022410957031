import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { IRegistro } from '../../../types';
import { RegistroService } from '../../../api/registro.service';
// @ts-ignore
import { saveAs } from 'file-saver/dist/FileSaver';

@Component({
  selector: 'app-confirmar',
  templateUrl: './confirmar.component.html',
  styleUrls: ['./confirmar.component.scss'],
})
export class ConfirmarComponent implements OnInit {
  @Input() data!: IRegistro;
  procesando: boolean;
  exito: boolean;
  error: any;
  @Output() cerrar = new EventEmitter<boolean>();

  constructor(private registroService: RegistroService) {
    this.procesando = false;
    this.exito = false;
    this.error = null;
  }

  ngOnInit(): void {
  }

  confirmar(): void {
    if (this.procesando) {
      return;
    }

    this.procesando = true;
    this.error = null;

    this.registroService.post(this.data).toPromise()
      .then((data: object) => {
        this.exito = true;
        // @ts-ignore
        const blob = new Blob([data], {type: 'application/pdf'});
        const name = `${this.data.nombre}${this.data.apellidoPaterno}.pdf`;
        saveAs(blob, name);
      })
      .catch(({error}) => {
        this.error = 'Error Intente de nuevo';
      })
      .finally(() => (this.procesando = false));

  }

  aceptar(): void {
    if (!this.exito) {
      this.cerrar.emit(false);
    } else {
      this.cerrar.emit(true);
    }
  }
}
