import { Component, Input, OnInit } from "@angular/core";
import { IRegistroDashboard } from "src/app/types";

@Component({
  selector: 'app-dashboard-registros',
  templateUrl: './registros.component.html',
})
export class DashboardRegistrosComponent implements OnInit {
  @Input() data!: IRegistroDashboard;

  constructor() {
  }

  ngOnInit(): void {
  }
}