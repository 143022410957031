<div class="at-modal">
  <div class="at-card margin-md" style="width: 350px">
    <div class="at-item">
      <div class="flex">
        <div class="typo-subhead">Generar QR</div>
      </div>
      <button class="at-icon"
        (click)="aceptar()"><i class="fas fa-times"></i></button>
    </div>

    <div *ngIf="!procesando && !exito">
      <div class="margin-md">
        <p>¿Desea generar y mandar por correo el pase QR a <b>{{data.nombres}}</b>? </p>
      </div>
      <div class="margin-md" *ngIf="error">
        <p class=" --text-error">{{error}}</p>
      </div>
      <div class="at-item">
        <button class="at-button at-button--primary"
          (click)="confirmar()">Confirmar
        </button>
        <button class="at-button "
          (click)="aceptar()">Cancelar
        </button>
      </div>
    </div>

    <div *ngIf="procesando && !exito">
      <div class="margin-md">
        <p class="--text-small">Generando QR.... </p>
      </div>
    </div>

    <div *ngIf="!procesando && exito">
      <div class="margin-md">
        <p class="--text-small"><i class="fas fa-check-circle --text-success"></i> QR exitoso</p>
      </div>
      <div class="at-item">
        <button class="at-button at-button--primary"
          (click)="aceptar()">Aceptar
        </button>
      </div>
    </div>
  </div>
</div>


