import { Component, OnInit } from '@angular/core';
import { IniciarService } from '../../../sesion/api/iniciar.service';
import { Router } from '@angular/router';
import { IDashboard } from 'src/app/types';
import { Observable, interval } from 'rxjs';
import { DashboardService } from 'src/app/api/dashboard.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  dashboard!: Observable<IDashboard>;
  permisos!: string | null;

  constructor(
    private sesionService: IniciarService,
    private dashboardService: DashboardService
  ) { }

  ngOnInit(): void {
    this.dashboard = this.dashboardService.get()
    this.permisos = this.sesionService.getSessionDahsboard()

    const intervale$ = interval(60000)
    intervale$.subscribe((e: number) => {
      this.dashboard = this.dashboardService.get()
      this.permisos = this.sesionService.getSessionDahsboard()
    })
  }
}
