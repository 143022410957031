import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IRegistro } from '../../../../types';
import { PagoService } from '../../../../api/pago.service';

@Component({
  selector: 'app-registros-editar-pago',
  templateUrl: './pago.component.html',
  styleUrls: ['./pago.component.scss'],
})
export class PagoComponent implements OnInit {
  @Input() data!: IRegistro;
  @Input() tipo!: string;
  procesando: boolean;
  exito: boolean;
  error: any;
  @Output() cerrar = new EventEmitter<boolean>();

  constructor(
    private pagoService: PagoService,
  ) {
    this.procesando = false;
    this.exito = false;
    this.error = null;
  }

  ngOnInit(): void {
    console.info('datis', this.data, this.tipo)
  }

  confirmar(): void {
    if (this.procesando) {
      return;
    }

    this.procesando = true;
    this.error = null;

    this.pagoService.put(this.data, this.tipo).subscribe({
      next: () => { this.exito = true },
      error: (err) => { this.error = err.error }
    }).add(() => { this.procesando = false })
  }

  aceptar(): void {
    if (!this.exito) {
      this.cerrar.emit(false);
    } else {
      this.cerrar.emit(true);
    }
  }

}
