<style>
  .container-scroll {
    width: 100%;
    overflow-y: auto;
    height: 150px;
  }

  .container-scroll::-webkit-scrollbar {
    width: 12px;
  }

  .container-scroll::-webkit-scrollbar-thumb {
    background-color: var(--success__color);
    border-radius: 20px;
    border: 3px solid var(--primary__text);
  }
</style>

<div class="at-item padding-md">
  <div class="flex">
    <div class="--text-light typo-subhead">Historial de Pagos</div>
  </div>
  <a><i class="pi pi-external-link"></i></a>
</div>
<p align="center" *ngIf="!data.length">
  Sin historial de pagos realizados...
</p>
<div class="container-scroll">
  <div class="at-item margin-ss padding-md" *ngFor="let historial of data">
    <div class="flex">
      <div class="--text-light">{{historial.nombres}}</div>
      <div class="--text-bold">{{historial.fecha}}</div>
    </div>
    <div class="text-aling-rigth">
      <div class="--text-light">Estatus: Pagado</div>
      <div class="--text-bold">${{historial.pago}}</div>
    </div>
  </div>
</div>
