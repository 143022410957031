<div class="at-item at-item--large at-card" *ngIf="!ver" 
  (click)="ver = true">
  <div class="flex">
    <div>{{data.nombres}}</div>
    <div class="--text-small">IGLESIA: {{data.procedencia}} | Distrito: {{data.distrito}}</div>
  </div>
  <div class="text-align-right" style="width: 120px">
    <div class="--text-small --text-light">Fecha de Registro</div>
    <div>{{data.fechaRegistro | date : 'mediumDate'}}</div>
  </div>
  <div *ngIf="data.pagado == '1'" 
    title="Pagado" 
    class="at-icon --text-success">
    <i class="fas fa-check-circle"></i>
  </div>
  <div *ngIf="data.pagado == '0'" 
    title="No pagado" 
    class="at-icon --text-error">
    <i class="fas fa-times-circle"></i>
  </div>
</div>

<app-registros-editar *ngIf="ver" 
  [data]="data" 
  [tipo]="tipo" 
  (cerrar)="cerrar($event)"></app-registros-editar>