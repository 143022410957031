import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RegistrosDescargaService {

  constructor(private $http: HttpClient) { }

  get(fechaInicial: string, fechaFinal: string, tipo: string): Observable<Blob> {
    return this.$http.get<Blob>(`/backendemivi/api/registros-descarga`, {
      params: { fechaInicial, fechaFinal, tipo },
      responseType: 'blob' as 'json',
    });
  }
}
