import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CheckInService } from 'src/app/api/check-in.service';
import { ICheckIn, IPaises } from 'src/app/types';

@Component({
  selector: 'app-check-in',
  templateUrl: './template.html',
})
export class CheckInComponent implements OnInit {
  registroId!: number;
  tipoRegistro!: number;
  data!: ICheckIn;
  paises: IPaises = [
    { id: 1, descripcion: 'México' },
    { id: 2, descripcion: 'USA' },
    { id: 3, descripcion: 'Latinoamérica' },
    { id: 4, descripcion: 'Europa' },
    { id: 5, descripcion: 'Asia' },
  ];
  registro: IPaises = [
    { id: 0, descripcion: 'Plantación' },
    { id: 1, descripcion: 'Obispo' },
    { id: 2, descripcion: 'Pastor' },
    { id: 3, descripcion: 'Ministro' },
    { id: 4, descripcion: 'Diacono' },
    { id: 5, descripcion: 'Lider' },
    { id: 6, descripcion: 'Otros' },
  ];
  codigo: number;

  constructor(
    private route: ActivatedRoute,
    private checkInService: CheckInService
  ) {
    this.codigo = 54321;
    this.registroId = Number(this.route.snapshot.paramMap.get('registroId'));
    this.tipoRegistro = Number(this.route.snapshot.paramMap.get('tipoRegistro'));
  }

  ngOnInit(): void {
    this.checkInService.post(this.registroId, this.codigo, this.tipoRegistro)
      .subscribe({
        next: (data: ICheckIn) => this.data = data,
        error: (error) => { }
      })
  }
}
