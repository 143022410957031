<div class="ed-content">
  <div class="padding-md">
    <h1 class="typo-display margin-lg">
      Registros
    </h1>
  </div>

  <div class="margin-md padding-md">
    <form name="form" #form="ngForm" autocomplete="off">
      <div class="at-fieldset margin-md">
        <div class="at-control at-fieldset--span-4">
          <label class="--text-small --text-light">Fecha Inicial</label>
          <input class="at-input" 
            required 
            type="date" 
            placeholder="Fecha inical"
            name="fechaInical"
            [(ngModel)]="fechaInical" />
        </div>
        <div class="at-control at-fieldset--span-4">
          <label class="--text-small --text-light">Fecha final</label>
          <input class="at-input" 
            required 
            type="date" 
            placeholder="Fecha final" 
            name="fechaFinal"
            [(ngModel)]="fechaFinal">
        </div>
        <div class="at-control at-fieldset--span-4">
          <label class="--text-small --text-light">Tipo Registro</label>
          <select
            class="at-input"
            required
            name="paisId"
            #paisId="ngModel"
            [(ngModel)]="tipo">
            <option value="0">Todos</option>
            <option value="1">Emivi general</option>
            <option value="2">Iglesias en nacimiento</option>
            <option value="3">Emivi + materia</option>
          </select>
        </div>
      </div>
      <div class="at-item">
        <button class="at-button at-button--primary at-button--raised" 
          type="button" 
          (click)="buscar()"
          [disabled]="form.invalid">
          Buscar
        </button>
        <button class="at-button at-button--raised" 
          type="button"
          (click)="descargar()" 
          [disabled]="form.invalid">
          Descargar
        </button>
      </div>
    </form>
  </div>
  
  <ng-template #loading>
    <div class="at-item at-item--large">
      <i class="fas fa-atom ed-rodar" style="font-size: 1.5rem"></i>&nbsp;Cargando información ....
    </div>
  </ng-template>
  <div *ngIf="promise$ | async as data; else loading;">
    <div class="at-fieldset margin-md" *ngIf="data.length > 0">
      <div class="at-control at-fieldset--span-12">
        <input class="at-input" 
          type="text" 
          placeholder="Buscar" 
          [(ngModel)]="filtro">
      </div>
    </div>

    <p class="--text-small --text-light" 
      *ngIf="data.length > 0">{{data.length}} registros encontrados</p>
    <p class="--text-small --text-light" 
      *ngIf="!data.length">{{data.length}} registros no encontrados</p>
    <app-registros-lista *ngFor="let registro of data | filter: filtro" 
        [data]="registro"
        [tipo]="tipo"></app-registros-lista>
  </div>
</div>

<app-sesion-interface-modal-descarga *ngIf="descargando"
  [titulo]="'Reporte'"
  [procesando]="procesando"
  [error]="error"
  [exito]="exito"
  (cerrar)="descargando = false"></app-sesion-interface-modal-descarga>