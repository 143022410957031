import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IRegistro } from '../types';

@Injectable({
  providedIn: 'root',
})
export class RegistroService {

  constructor(private $http: HttpClient) {
  }

  get(fechaInicial: string, fechaFinal: string, tipo: string): Observable<IRegistro[]> {
    return this.$http.get<IRegistro[]>(`/backendemivi/api/registro`, { params: { fechaInicial, fechaFinal, tipo } });
  }


  post(data: IRegistro): Observable<object> {
    return this.$http.post(`/backendemivi/api/registro`, data, { responseType: 'blob' as 'json' });
  }
}
