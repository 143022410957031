<div class="at-modal">
  <div class="at-card margin-md" style="width: 450px; height: 175px;">
    <div class="at-item at-item--large">
      <div class="flex">
        <div class="typo-subhead">Confirmar</div>
      </div>
      <button class="at-icon" 
        type="button"
        (click)="aceptar()">
        <i class="fas fa-times"></i>
      </button>
    </div>

    <div *ngIf="!procesando && !exito">
      <div class="margin-md">
        <p class="--text-bold">Desea terminar el con registro? </p>
      </div>
      <div class="margin-md" *ngIf="error">
        <p class=" --text-error">{{error.message}}</p>
      </div>
      <div class="at-item at-item--large">
        <button class="at-button at-button--primary" 
          type="button"
          (click)="confirmar()">Confirmar
        </button>
        <button class="at-button"
          type="button"
          (click)="aceptar()">Cancelar
        </button>
      </div>
    </div>

    <div *ngIf="procesando && !exito">
      <div class="margin-md">
        <p class="--text-small">Registrando.... </p>
      </div>
    </div>

    <div *ngIf="!procesando && exito">
      <div class="margin-md">
        <p class=" --text-small">
          <i class="fas fa-check-circle --text-success"></i> Registro Exitoso 
        </p>
      </div>
      <div class="at-item">
        <button class="at-button at-button--primary" 
          type="button"
          (click)="aceptar()">Aceptar
        </button>
      </div>
    </div>
  </div>
</div>