import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RegistroRegistroService } from 'src/app/api/registro-registro.service';
import { IPaises } from '../../../types';

@Component({
  selector: 'app-registros-editar',
  templateUrl: './editar.component.html',
  styleUrls: ['./editar.component.scss'],
})
export class EditarComponent implements OnInit {
  @Input() data: any;
  @Input() tipo!: string;
  @Output() cerrar: EventEmitter<boolean> = new EventEmitter();
  paises: IPaises;
  registro: IPaises;
  nacimiento: IPaises;
  programa: IPaises;
  pago: boolean;
  qr: boolean;
  procesando: boolean;
  error: null;
  descripcion = '';


  constructor(private registroService: RegistroRegistroService) {
    this.paises = [
      { id: 1, descripcion: 'México' },
      { id: 2, descripcion: 'USA' },
      { id: 3, descripcion: 'Latinoamérica' },
      { id: 4, descripcion: 'Europa' },
      { id: 5, descripcion: 'Asia' },
    ];
    this.registro = [
      { id: 1, descripcion: 'Obispo' },
      { id: 2, descripcion: 'Pastor' },
      { id: 3, descripcion: 'Ministro' },
      { id: 4, descripcion: 'Diacono' },
      { id: 5, descripcion: 'Lider' },
      { id: 6, descripcion: 'Otros' },
    ];
    this.nacimiento = [
      { id: 1, descripcion: 'Sin materias' },
      { id: 2, descripcion: 'Validar materias' },
    ];
    this.programa = [
      { id: 1, descripcion: 'Emivi general' },
      { id: 2, descripcion: 'Iglesias en nacimiento' },
      { id: 3, descripcion: 'Emivi + materia' },
    ];
    this.pago = false;
    this.qr = false;
    this.procesando = false;
    this.error = null;
  }

  ngOnInit(): void {
    this.descripcion = this.registro[(+this.data.tipoRegistroId) - 1].descripcion;
  }

  pagado(event: any): void {
    this.pago = false;
    if (event) {
      this.data.pagado = '1';
    }
  }

  generarQR(event: any): void {
    this.qr = false;
    if (event) {
      console.log('qr enviado');
    }
  }

  editar(form: any): void {
    if (this.procesando) {
      return;
    }

    this.procesando = true;

    this.registroService.put(this.data).subscribe({
      next: () => { form.form.pristine = true },
      error: (err) => { this.error = err.error },
    }).add(() => { this.procesando = false })
  }
}
