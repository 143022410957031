import { Component, OnInit } from '@angular/core';
import { RegistroService } from '../../api/registro.service';
import { Observable, of } from 'rxjs';
import { IRegistro } from 'src/app/types';
import { RegistrosDescargaService } from '../../api/registros-descarga.service';
import * as saveAs from 'file-saver';

export const formatearFecha = (time: Date) => {
  time = new Date(time)

  return `${time.getFullYear()}-${(time.getMonth() + 1).toString().padStart(2, '0')}-${time
    .getDate()
    .toString()
    .padStart(2, '0')}`
}

export const restarFechasMeses = (time: Date, meses: number) => {
  time = new Date(time)
  time.setMonth(time.getMonth() - meses)

  return time
}

@Component({
  selector: 'app-registros',
  templateUrl: './registros.component.html',
  styleUrls: ['./registros.component.scss'],
})
export class RegistrosComponent implements OnInit {
  filtro: string;
  tipo!: string
  fechaInical!: string
  fechaFinal!: string
  descargando!: boolean
  procesando!: boolean
  error!: string | null
  exito!: boolean
  promise$: Observable<IRegistro[]> = of([]);

  constructor(private registroService: RegistroService, private registrosDescargaService: RegistrosDescargaService) {
    this.filtro = '';
    this.tipo = '0'
    this.fechaInical = formatearFecha(restarFechasMeses(new Date(), 1))
    this.fechaFinal = formatearFecha(new Date())
    this.descargando = false
    this.procesando = false
    this.error = null
    this.exito = false
  }

  ngOnInit(): void { }

  buscar(): void {
    this.promise$ = this.registroService.get(this.fechaInical, this.fechaFinal, this.tipo)
  }

  descargar(): void {
    if (this.descargando && this.procesando) {
      return
    }

    this.descargando = true
    this.procesando = true
    this.exito = false
    this.error = null
    this.registrosDescargaService.get(this.fechaInical, this.fechaFinal, this.tipo)
      .subscribe({
        next: (data) => {
          this.exito = true
          const blob = new Blob([data], { type: 'application/vnd.ms-excel;charset=utf-8' });
          const name = 'Registros.xlsx';
          saveAs(blob, name);
        },
        error: (error) => { this.error = error.error; }
      }).add(() => { this.procesando = false })
  }
}
