<div class="container">
  <aside class="sidebar open" *ngIf="!current" data-sidebar>
    <div class="top-sidebar-first">
      <button class="menu-icon-btn" data-menu-icon-btn (click)="menu()">
        <svg viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet" focusable="false" class="menu-icon">
          <g>
            <path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z"></path>
          </g>
        </svg>
      </button>
    </div>
    <div class="top-sidebar margin-md">
      <a href="#" class="channel-logo"><img src="assets/mision.png" alt="Channel Logo"></a>
      <div class="hidden-sidebar your-channel">Iglesia La Misión</div>
      <div class="hidden-sidebar channel-name">EMIVI WEB</div>
    </div>
    <div class="middle-sidebar">
      <ul class="sidebar-list">
        <li class="sidebar-list-item">
          <a routerLink="/dashboard" class="sidebar-link">
            <svg class="sidebar-icon" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet" focusable="false">
              <g>
                <path d="M3 13h8V3H3v10zm0 8h8v-6H3v6zm10 0h8V11h-8v10zm0-18v6h8V3h-8z"></path>
              </g>
            </svg>
            <div class="hidden-sidebar">Dashboard</div>
          </a>
        </li>
        <li class="sidebar-list-item">
          <a routerLink="/registros" class="sidebar-link">
            <svg viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet" focusable="false" class="sidebar-icon">
              <g>
                <path
                  d="M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zM9 17H7v-7h2v7zm4 0h-2V7h2v10zm4 0h-2v-4h2v4z">
                </path>
              </g>
            </svg>
            <div class="hidden-sidebar">Registros</div>
          </a>
        </li>
        <li class="sidebar-list-item">
          <a routerLink="/verificador-url" class="sidebar-link">
            <svg viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet" focusable="false" class="sidebar-icon">
              <g>
                <path
                  d="M20 4H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zM4 12h4v2H4v-2zm10 6H4v-2h10v2zm6 0h-4v-2h4v2zm0-4H10v-2h10v2z">
                </path>
              </g>
            </svg>
            <div class="hidden-sidebar">Check In</div>
          </a>
        </li>
        <li class="sidebar-list-item">
          <a routerLink="/informacion" target="_blank" class="sidebar-link">
            <svg class="sidebar-icon" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet" focusable="false">
              <g>
                <path d="M3 13h8V3H3v10zm0 8h8v-6H3v6zm10 0h8V11h-8v10zm0-18v6h8V3h-8z"></path>
              </g>
            </svg>
            <div class="hidden-sidebar">Registro para EMIVI</div>
          </a>
        </li>
        <li class="sidebar-list-item">
          <a routerLink="/informacion"  target="_blank" class="sidebar-link">
            <svg class="sidebar-icon" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet" focusable="false">
              <g>
                <path d="M3 13h8V3H3v10zm0 8h8v-6H3v6zm10 0h8V11h-8v10zm0-18v6h8V3h-8z"></path>
              </g>
            </svg>
            <div class="hidden-sidebar">Registro para Plantación</div>
          </a>
        </li>
      </ul>
    </div>
    <div class="bottom-sidebar">
      <ul class="sidebar-list">
        <li class="sidebar-list-item">
          <a class="sidebar-link">
            <svg viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet" focusable="false" class="sidebar-icon">
              <g>
                <path
                  d="M19.43 12.98c.04-.32.07-.64.07-.98s-.03-.66-.07-.98l2.11-1.65c.19-.15.24-.42.12-.64l-2-3.46c-.12-.22-.39-.3-.61-.22l-2.49 1c-.52-.4-1.08-.73-1.69-.98l-.38-2.65C14.46 2.18 14.25 2 14 2h-4c-.25 0-.46.18-.49.42l-.38 2.65c-.61.25-1.17.59-1.69.98l-2.49-1c-.23-.09-.49 0-.61.22l-2 3.46c-.13.22-.07.49.12.64l2.11 1.65c-.04.32-.07.65-.07.98s.03.66.07.98l-2.11 1.65c-.19.15-.24.42-.12.64l2 3.46c.12.22.39.3.61.22l2.49-1c.52.4 1.08.73 1.69.98l.38 2.65c.03.24.24.42.49.42h4c.25 0 .46-.18.49-.42l.38-2.65c.61-.25 1.17-.59 1.69-.98l2.49 1c.23.09.49 0 .61-.22l2-3.46c.12-.22.07-.49-.12-.64l-2.11-1.65zM12 15.5c-1.93 0-3.5-1.57-3.5-3.5s1.57-3.5 3.5-3.5 3.5 1.57 3.5 3.5-1.57 3.5-3.5 3.5z">
                </path>
              </g>
            </svg>
            <div class="hidden-sidebar"><span class="typo-body-2">Configuración</span></div>
          </a>
        </li>
        <li class="sidebar-list-item">
          <a class="sidebar-link">
            <svg class="sidebar-icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M837.914838 774.764566c0-27.980261-10.703783-52.738132-31.607882-68.308759-58.392922-39.787168-104.661711-64.805982-186.714496-100.475366-20.726044-7.637957-34.792411-27.450189-34.792411-50.726312 0-16.423041 7.087419-31.924083 19.368116-41.012066 73.433476-54.32221 83.992973-214.32125 83.992973-214.32125 0-94.293573-76.738755-170.732499-171.511235-170.732499-94.770434 0-172.122149 76.5607-172.122149 170.854273 0 0 4.312212 123.051547 51.780317 178.942485l0.004093 0c9.496282 13.553691 19.436678 25.414833 31.737842 35.378765 11.610432 9.925047 19.295462 24.52865 19.295462 40.951691 0 23.280216-14.644535 43.060725-35.370579 50.698682-68.563562 28.569686-126.814244 59.249429-188.084703 100.460016-20.904099 15.569604-34.46086 40.320311-34.46086 68.300572 0 39.774889 27.398 73.092715 64.401776 82.573647 0 0 95.646384 40.135092 256.966513 40.135092 160.177096 0 256.967536-40.078811 256.967536-40.11872l0-0.020466C804.176432 847.862397 837.914838 814.539454 837.914838 774.764566L837.914838 774.764566zM837.914838 774.764566" />
            </svg>
            <div class="hidden-sidebar"><app-sesion-usuario></app-sesion-usuario></div>
          </a>
        </li>
      </ul>
    </div>
  </aside>
  <main class="content">
    <router-outlet></router-outlet>
  </main>
</div>