<div class="at-card">
  <div class="at-item at-item--large">
    <div class="flex">
      <div class="typo-subhead">Editar</div>
    </div>
    <button class="at-icon"
      type="button"
      (click)="cerrar.emit(true)">
      <i class="fas fa-times"></i>
    </button>
  </div>
  <div class="margin-md">
    <form name="form"
      #form="ngForm"
      autocomplete="off"
      (ngSubmit)="editar(form)">
      <div class="at-fieldset margin-md">
        <div class="at-control at-fieldset--span-6">
          <label class="--text-small --text-light">Nombre</label>
          <input class="at-input"
            required
            type="text"
            placeholder="Nombre(s)"
            name="nombre"
            #nombre="ngModel"
            [(ngModel)]="data.nombre">
          <ul class="at-control_messages" *ngIf="nombre.errors && nombre.touched">
            <li *ngIf="nombre.errors.required">
              Ingrese un nombre
            </li>
          </ul>
        </div>
        <div class="at-control at-fieldset--span-3">
          <label class="--text-small --text-light">Apellido paterno</label>
          <input class="at-input"
            required
            type="text"
            placeholder="Apellido Paterno"
            name="apellidoPaterno"
            #apellidoPaterno="ngModel"
            [(ngModel)]="data.apellidoPaterno">
          <ul class="at-control_messages" *ngIf="apellidoPaterno.errors && apellidoPaterno.touched">
            <li *ngIf="apellidoPaterno.errors.required">
              Ingrese un apellido
            </li>
          </ul>
        </div>
        <div class="at-control at-fieldset--span-3">
          <label class="--text-small --text-light">Apellido materno</label>
          <input class="at-input"
            type="text"
            placeholder="Apellido Materno"
            name="apellidoMaterno"
            #apellidoMaterno="ngModel"
            [(ngModel)]="data.apellidoMaterno">
        </div>
      </div>
      <div class="at-fieldset">
        <div class="at-control at-fieldset--span-6">
          <label class="--text-small --text-light">Email</label>
          <input class="at-input"
            required
            placeholder="email@ejemplo.com"
            pattern="[a-zA-Z0-9_]+([.][a-zA-Z0-9_]+)*@[a-zA-Z0-9_]+([.][a-zA-Z0-9_]+)*[.][a-zA-Z]{1,5}"
            type="text"
            name="email"
            #email="ngModel"
            [(ngModel)]="data.email">
          <ul class="at-control_messages" *ngIf="email.errors && email.touched">
            <li *ngIf="email.errors.required">Email requerido</li>
            <li *ngIf="email.errors.pattern">Email en formato incorrecto</li>
          </ul>
        </div>
        <div class="at-control at-fieldset--span-3">
          <label class="--text-small --text-light">Celular</label>
          <input class="at-input"
            required
            placeholder="Celular"
            type="number"
            maxlength="20"
            name="celular"
            #celular="ngModel"
            [(ngModel)]="data.celular">
          <ul class="at-control_messages" *ngIf="celular.errors && celular.touched">
            <li *ngIf="celular.errors.required">
              Ingrese un # celular
            </li>
          </ul>
        </div>
        <div class="at-control at-fieldset--span-3">
          <label class="--text-small --text-light">Tipo registro</label>
          <input class="at-input"
            required
            placeholder="Tipo registro"
            type="input"
            maxlength="20"
            name="tipoRegistro"
            #celular="ngModel"
            [(ngModel)]="descripcion">
        </div>
      </div>
      <div class="at-fieldset margin-md">
        <div class="at-control at-fieldset--span-3">
          <label class="--text-small --text-light">País</label>
          <select class="at-input"
            required
            name="paisId"
            #paisId="ngModel"
            [(ngModel)]="data.paisId">
            <option [value]="item.id" *ngFor="let item of paises">{{item.descripcion}}</option>
          </select>
        </div>
        <div class="at-control at-fieldset--span-3">
          <label class="--text-small --text-light">Estado</label>
          <input class="at-input"
            required
            type="text"
            placeholder="Estado"
            name="estado"
            #estado="ngModel"
            [(ngModel)]="data.estado">
          <ul class="at-control_messages" *ngIf="estado.errors && estado.touched">
            <li *ngIf="estado.errors.required">
              Ingrese el estado de procedencia
            </li>
          </ul>
        </div>
        <div class="at-control at-fieldset--span-3">
          <label class="--text-small --text-light">Ciudad</label>
          <input class="at-input"
            required
            type="text"
            placeholder="Ciudad"
            name="ciudad"
            #ciudad="ngModel"
            [(ngModel)]="data.ciudad">
          <ul class="at-control_messages" *ngIf="ciudad.errors && ciudad.touched">
            <li *ngIf="ciudad.errors.required">
              Ingrese la ciudad de procedencia
            </li>
          </ul>
        </div>
        <div class="at-control at-fieldset--span-3">
          <label class="--text-small --text-light">Código postal</label>
          <input class="at-input"
            required
            type="text"
            placeholder="Código Postal"
            name="codigoPostal"
            #codigoPostal="ngModel"
            maxlength="10"
            [(ngModel)]="data.codigoPostal">
          <ul class="at-control_messages" *ngIf="codigoPostal.errors && codigoPostal.touched">
            <li *ngIf="codigoPostal.errors.required">
              Ingrese el codigo postal
            </li>
          </ul>
        </div>
      </div>
      <div class="at-fieldset margin-md">
        <div class="at-control">
          <label class="--text-small --text-light">Dirección</label>
          <input class="at-input"
            required
            type="text"
            placeholder="Dirección"
            name="direccion"
            #direccion="ngModel"
            [(ngModel)]="data.direccion">
          <ul class="at-control_messages" *ngIf="direccion.errors && direccion.touched">
            <li *ngIf="direccion.errors.required">
              Ingrese la dirección
            </li>
          </ul>
        </div>
      </div>
      <div class="at-fieldset margin-md">
        <div class="at-control at-fieldset--span-4">
          <label class="--text-small --text-light">Procedencia</label>
          <input class="at-input"
            required
            type="text"
            placeholder="Iglesia de procedencia"
            name="procedencia"
            #procedencia="ngModel"
            [(ngModel)]="data.procedencia">
          <ul class="at-control_messages" *ngIf="procedencia.errors && procedencia.touched">
            <li *ngIf="procedencia.errors.required">
              Ingrese la iglesia de porcedencia
            </li>
          </ul>
        </div>
        <div class="at-control at-fieldset--span-4">
          <label class="--text-small --text-light">Distrito</label>
          <input class="at-input"
            type="text"
            placeholder="Distrito"
            name="distrito"
            #distrito="ngModel"
            [(ngModel)]="data.distrito">
        </div>
        <div class="at-control at-fieldset--span-4">
          <label class="--text-small --text-light">Fecha registro</label>
          <input class="at-input"
            required
            type="text"
            placeholder="Fecha de registro"
            name="regsitro"
            value="{{data.fechaCaptura | date: 'medium'}}">
        </div>
      </div>
      <div class="at-fieldset margin-md">
        <div class="at-control at-fieldset--span-6">
          <label class="--text-small --text-light">Programa Emivi</label>
          <select class="at-input" 
            required 
            name="tipoRegistroId" 
            #tipoRegistroId="ngModel"
            [(ngModel)]="data.emiviId">
            <option value="0">Selecciona una opción</option>
            <option [value]="item.id" *ngFor="let item of programa">
              {{ item.descripcion }}
            </option>
          </select>
        </div>
        <div class="at-control at-fieldset--span-6" *ngIf="data.emiviId == 2">
          <label class="--text-small --text-light">Materias</label>
          <select class="at-input" 
            required 
            name="tipoRegistroId" 
            #tipoRegistroId="ngModel"
            [(ngModel)]="data.materias">
            <option value="0">Selecciona una opción</option>
            <option [value]="item.id" *ngFor="let item of nacimiento">
              {{ item.descripcion }}
            </option>
          </select>
        </div>
      </div>
      <div class="at-item">
        <div class="flex">
          <button class="at-button at-button--primary at-button--raised"
            *ngIf="form.dirty"
            [disabled]="form.invalid || procesando"
            type="submit">
            Editar
          </button>
        </div>
        <button class="at-button at-button--primary at-button--raised"
          *ngIf="data.pagado == '1'"
          (click)="qr = true"
          type="button">
          Generar QR
        </button>
        <button class="at-button"
          type="button"
          (click)="pago = true"
          [disabled]="data.pagado == '1'">
          Marcar como pagado
        </button>
      </div>
      <div *ngIf="error" class="--text-error">
        {{error}}
      </div>
    </form>

    <app-registros-editar-pago
      *ngIf="pago"
      [data]="data"
      [tipo]="tipo"
      (cerrar)="pagado($event)"></app-registros-editar-pago>
    <app-registros-editar-qr
      *ngIf="qr"
      [data]="data"
      [tipo]="tipo"
      (cerrar)="generarQR($event)"></app-registros-editar-qr>
  </div>
</div>


