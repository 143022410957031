<button class="at-button typo-title"
  type="button"
  *ngIf="!user"
  routerLink="/sesion/login">
  Iniciar sesión
</button>
<button class="at-button typo-title"
  *ngIf="user"
  (click)="logout()">
  {{user.nombre}}
  <i class="fas fa-sign-out-alt"></i>
</button>
