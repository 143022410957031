import { Injectable } from '@angular/core';
import { ICredecial, ICredecialRespuesta, IUsuarioLogin } from '../type';
import { HttpClient } from '@angular/common/http';
import { JwtService } from './jwt.service';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class IniciarService {
  authPromise = undefined;
  authPromisecurrent: object | undefined;

  constructor(
    private $http: HttpClient,
    private jwt: JwtService,
    private router: Router) {
  }

  auth(credecial: ICredecial): Observable<ICredecialRespuesta> {
    return this.$http.post<ICredecialRespuesta>(`/backendemivi/api/sesion/token`, credecial)
      .pipe(map((data: ICredecialRespuesta) => {
        this.setSession(data.token);
        this.setSessionDahsboard(credecial.username);
        return data;
      }));
  }

  unauth(): void {
    this.setSession(null);
    this.router.navigateByUrl('/sesion/terminada').then(r => (r));
  }

  isAuth(): boolean {
    const token = window.localStorage.getItem('ng-token');
    return !!(token && this.jwt.isExpired(token) === false);
  }


  setSession(jwt: string | null): void {
    if (jwt) {
      window.localStorage.setItem('ng-token', jwt);
    } else {
      window.localStorage.removeItem('ng-token');
      window.localStorage.removeItem('ng-aka');
    }
  }

  setSessionDahsboard(username: string) {
    window.localStorage.setItem('ng-aka', username);
  }

  getSessionDahsboard(): string | null {
    return window.localStorage.getItem('ng-aka');
  }

  current(): Observable<IUsuarioLogin> {
    return this.$http.get<IUsuarioLogin>(`/backendemivi/api/sesion/current`);
  }
}
