import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-sesion-interface-modal-descarga',
  templateUrl: './modal-descarga.html',
})
export class SesionInterfaceModalDescargaComponent implements OnInit {
  @Input() titulo!: string;
  @Input() procesando!: boolean;
  @Input() error!: string | null;
  @Input() exito!: boolean;
  @Output() cerrar: EventEmitter<void> = new EventEmitter();

  constructor() { }

  ngOnInit(): void { }

  onDestroy(): void { this.cerrar.emit() }
}