import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ICheckIn } from '../types';

@Injectable({
 providedIn: 'root',
})
export class CheckInService {

 constructor(private $http: HttpClient) {
 }

 post(registroId: number, codigo: number, tipoRegistro: number): Observable<ICheckIn> {
  return this.$http.post<ICheckIn>(`/backendemivi/api/check-in/${registroId}`, {codigo, tipoRegistro});
 }
}
