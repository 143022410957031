<div class="ed-content">
  <div class="at-header-center">
    <div class="toolbar-container">
      <div class="toolbar-container-header">
        <div class="toolbar-header ma">
          <a class="typo-display">Informacion de cursos</a>
        </div>
      </div>
    </div>
  </div>

  <div class="container-card margin-md">
    <div class="card" (click)="ir('/registro', 1)">
      <img src="../../../assets/EMIVIgeneral1.jpg">
      <p class="--text-bold">
        EMIVI <br>
        <small>Costo $600 MXN</small>
      </p>
      <a>Inscríbete ya</a>
    </div>
    <div class="card" (click)="ir('/registro', 2)">
      <img src="../../../assets/iglesias en nacimiento 1.jpg">
      <p class="--text-bold">
        Valida 3 materias en Bachillerato en Iglecrecimiento
        <br> <small>Costo $2500 MXN</small>
      </p>
      <a>Inscríbete ya</a>
    </div>
    <div class="card" (click)="ir('/registro', 3)">
      <img src="../../../assets/EMIVI + materia.jpg">
      <p class="--text-bold">Valida 1 materia en Bachillerato en Iglecrecimiento
        <br><small>Costo $900 MXN</small>
      </p>
      <a>Inscríbete ya</a>
    </div>
  </div>
</div>