<div class="ed-content">
  <div class="at-header-center">
    <div class="at-item padding-md">
      <h1 class="typo-display margin-lg">Verificador</h1>
    </div>
    <div class="margin-lg">
      <form name="form"
        #form="ngForm"
        autocomplete="off"
        (ngSubmit)="verificar()">
        <div class="at-fieldset margin-md">
          <div class="at-control at-fieldset--span-12">
            <input placeholder="Inserte una URL"
              class="at-input"
              required
              type="url"
              name="nombre"
              #nombre="ngModel"
              [(ngModel)]="url"
            />
          </div>
        </div>
        <div class="at-item">
          <button class="at-button at-button--primary at-button--raised"
            type="submit"
            [disabled]="form.invalid || !url">
            verificar
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
