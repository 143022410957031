<div class="at-item padding-md">
    <div class="flex">
      <div class="--text-light typo-subhead">Dias del evento</div>
    </div>
    <a><i class="pi pi-external-link"></i></a>
  </div>
  <div class="at-item margin-ss padding-md">
    <div class="flex">
      <div class="--text-light">
        1 de febrero
      </div>
    </div>
    <span class="--text-bold">{{data.fecha == date ? '..En proceso' : data.fecha < date ? 'Finalizado' : 'Sin iniciar'}}</span>
  </div>
  <div class="at-item margin-ss padding-md">
    <div class="flex">
      <div class="--text-light">
        2 de febrero
      </div>
    </div>
    <span class="--text-bold">{{data.fecha2 == date ? '..En proceso' : data.fecha2 < date ? 'Finalizado' : 'Sin iniciar'}}</span>
  </div>
  <div class="at-item margin-ss padding-md">
    <div class="flex">
      <div class="--text-light">
        3 de febrero
      </div>
    </div>
    <span class="--text-bold">{{data.fecha3 == date ? '..En proceso' : data.fecha3 < date ? 'Finalizado' : 'Sin iniciar'}}</span>
  </div>