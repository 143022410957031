import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { SesionIniciarComponent } from './components/iniciar/iniciar.component';
import { SesionTerminadaComponent } from './components/terminada/terminada.component';
import { FormsModule } from '@angular/forms';
import { AppRoutingModule } from '../app/app-routing.module';

@NgModule({
  declarations: [
    SesionIniciarComponent,
    SesionTerminadaComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
  ],
  providers: [],
  bootstrap: [],
  exports: [
    SesionIniciarComponent,
    SesionTerminadaComponent,
  ],
})
export class AppModuleSesion {
}