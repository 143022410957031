import { Component, OnInit } from '@angular/core';
import { IniciarService } from '../../api/iniciar.service';
import { Router } from '@angular/router';
import { ICredecial, ICredecialRespuesta } from '../../type';

@Component({
  selector: 'app-sesion-iniciar',
  templateUrl: './iniciar.component.html',
  styleUrls: ['./iniciar.component.scss'],
})
export class SesionIniciarComponent implements OnInit {
  error: any;
  procesando: boolean;
  credencial: ICredecial;
  form: unknown;

  constructor(private sesion: IniciarService, private router: Router) {
    this.error = null;
    this.procesando = false;
    this.credencial = { username: '', password: '' };
  }

  ngOnInit(): void {
    if (this.sesion.isAuth()) {
      this.router.navigateByUrl('/dashboard')
        .then();
    }
  }


  aceptar() {
    if (this.procesando) {
      return;
    }

    this.error = null;
    this.procesando = true;

    this.sesion.auth(this.credencial).subscribe({
      next: (data: ICredecialRespuesta) => {
        this.router.navigateByUrl('/dashboard');
      },
      error: (error) => {
        this.error = error.error;
      },
    }).add(() => this.procesando = false)
  }
}
