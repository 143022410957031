import { Component, OnInit } from '@angular/core';
import { IPaises, IRegistro } from '../../types';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-registro',
  templateUrl: './registro.component.html',
  styleUrls: ['./registro.component.scss'],
})
export class RegistroComponent implements OnInit {
  data: IRegistro;
  paises: IPaises;
  nacimiento: IPaises;
  registro: IPaises;
  enviar: boolean;
  terminar: boolean;
  emiviId: number;
  nombreRegistro: string;

  constructor(private route: ActivatedRoute) {
    this.emiviId = Number(this.route.snapshot.paramMap.get('id'));
    this.enviar = false;
    this.terminar = false;
    this.nombreRegistro = '';
    this.data = {
      nombres: '',
      nombre: '',
      apellidoPaterno: '',
      apellidoMaterno: '',
      email: '',
      celular: '',
      procedencia: '',
      distrito: 'N/A',
      paisId: 0,
      estado: '',
      ciudad: '',
      direccion: '',
      codigoPostal: '',
      tipoRegistroId: 0,
      materias: 0,
    };
    this.paises = [
      { id: 1, descripcion: 'México' },
      { id: 2, descripcion: 'USA' },
      { id: 3, descripcion: 'Latinoamérica' },
      { id: 4, descripcion: 'Europa' },
      { id: 5, descripcion: 'Asia' },
    ];
    this.registro = [
      { id: 1, descripcion: 'Obispo' },
      { id: 2, descripcion: 'Pastor' },
      { id: 3, descripcion: 'Ministro' },
      { id: 4, descripcion: 'Diacono' },
      { id: 5, descripcion: 'Lider' },
      { id: 6, descripcion: 'Otros' },
    ];
    this.nacimiento = [
      { id: 1, descripcion: 'Sin materias' },
      { id: 2, descripcion: 'Validar materias' },
    ];
  }

  ngOnInit(): void {
    this.data.emiviId = this.emiviId
    this.nombreRegistro = this.emiviId == 1 ? 'EMIVI GENERAL' : this.emiviId == 2 ? 'IGLESIAS EN NACIMIENTO' : 'EMIVI + MATERIA';
  }

  guardar(): void {
    this.enviar = true;
  }

  cerrar(event: boolean): void {
    this.enviar = false;

    if (event) {
      this.terminar = true;
    }
  }
}
