<div class="ed-content">
  <div class="at-header-center">
    <div class="toolbar-container">
      <div class="toolbar-container-header">
        <div class="toolbar-header">
          <a class="typo-display">Registro {{nombreRegistro}}</a>
        </div>
      </div>
    </div>
  </div>

  <div class="at-header-center" style="max-width: 700px">
    <div class="at-item padding-md">
      <h1 class="typo-display margin-lg" *ngIf="terminar">Registro Exitoso</h1>
    </div>
    <div class="margin-md" *ngIf="!terminar">
      <form name="form" #form="ngForm" autocomplete="off" (ngSubmit)="guardar()">
        <div class="at-fieldset margin-md">
          <div class="at-control at-fieldset--span-6">
            <label class="--text-small --text-light">
              Nombre</label>
            <input class="at-input" 
              required 
              type="text" 
              placeholder="Nombre(s)" 
              name="nombre" 
              #nombre="ngModel"
              [(ngModel)]="data.nombre" />
            <ul class="at-control_messages" *ngIf="nombre.invalid && (nombre.touched || nombre.dirty)">
              <li *ngIf="nombre.hasError('required')">Ingrese un nombre</li>
            </ul>
          </div>
          <div class="at-control at-fieldset--span-3">
            <label class="--text-small --text-light">
              Apellido paterno</label>
            <input class="at-input" 
              required 
              type="text" 
              placeholder="Apellido Paterno" 
              name="apellidoPaterno"
              #apellidoPaterno="ngModel" 
              [(ngModel)]="data.apellidoPaterno" />
            <ul class="at-control_messages" *ngIf="apellidoPaterno.invalid && (apellidoPaterno.touched || apellidoPaterno.dirty)">
              <li *ngIf="apellidoPaterno.hasError('required')">
                Ingrese un apellido
              </li>
            </ul>
          </div>
          <div class="at-control at-fieldset--span-3">
            <label class="--text-small --text-light">Apellido materno</label>
            <input class="at-input" 
              type="text" 
              placeholder="Apellido Materno" 
              name="apellidoMaterno"
              #apellidoMaterno="ngModel" 
              [(ngModel)]="data.apellidoMaterno" />
          </div>
        </div>
        <div class="at-fieldset">
          <div class="at-control at-fieldset--span-6">
            <label class="--text-small --text-light">Email</label>
            <input class="at-input" 
              required 
              placeholder="email@ejemplo.com"
              pattern="[a-zA-Z0-9_]+([.][a-zA-Z0-9_]+)*@[a-zA-Z0-9_]+([.][a-zA-Z0-9_]+)*[.][a-zA-Z]{1,5}" 
              type="text"
              name="email" 
              #email="ngModel" 
              [(ngModel)]="data.email" />
            <ul class="at-control_messages" *ngIf="email.invalid && (email.touched || email.dirty)">
              <li *ngIf="email.hasError('required')">Email requerido</li>
              <li *ngIf="email.hasError('pattern')">Email en formato incorrecto</li>
            </ul>
          </div>
          <div class="at-control at-fieldset--span-3">
            <label class="--text-small --text-light">Celular</label>
            <input class="at-input" 
              required 
              placeholder="Celular" 
              type="number" 
              maxlength="20" 
              name="celular"
              #celular="ngModel" 
              [(ngModel)]="data.celular" />
            <ul class="at-control_messages" *ngIf="celular.invalid && (celular.touched || celular.dirty)">
              <li *ngIf="celular.hasError('required')">Ingrese un # celular</li>
            </ul>
          </div>
          <div class="at-control at-fieldset--span-3">
            <label class="--text-small --text-light">Tipo de registro</label>
            <select class="at-input" 
              required 
              name="tipoRegistroId" 
              #tipoRegistroId="ngModel"
              [(ngModel)]="data.tipoRegistroId">
              <option value="0">Selecciona una opción</option>
              <option [value]="item.id" *ngFor="let item of registro">
                {{ item.descripcion }}
              </option>
            </select>
          </div>
        </div>
        <div class="at-fieldset margin-md">
          <div class="at-control at-fieldset--span-3">
            <label class="--text-small --text-light">País</label>
            <select class="at-input" 
              required 
              name="paisId" 
              #paisId="ngModel" 
              [(ngModel)]="data.paisId">
              <option value="0">Selecciona una opción</option>
              <option [value]="item.id" *ngFor="let item of paises">
                {{ item.descripcion }}
              </option>
            </select>
          </div>
          <div class="at-control at-fieldset--span-3">
            <label class="--text-small --text-light">Estado</label>
            <input class="at-input" 
              required 
              type="text" 
              placeholder="Estado" 
              name="estado" 
              #estado="ngModel"
              [(ngModel)]="data.estado" />
            <ul class="at-control_messages" *ngIf="estado.invalid && (estado.touched || estado.dirty)">
              <li *ngIf="estado.hasError('required')">
                Ingrese el estado de procedencia
              </li>
            </ul>
          </div>
          <div class="at-control at-fieldset--span-3">
            <label class="--text-small --text-light">Ciudad</label>
            <input class="at-input" 
              required 
              type="text" 
              placeholder="Ciudad" 
              name="ciudad" 
              #ciudad="ngModel"
              [(ngModel)]="data.ciudad" />
            <ul class="at-control_messages" *ngIf="ciudad.invalid && (ciudad.touched || ciudad.dirty)">
              <li *ngIf="ciudad.hasError('required')">
                Ingrese la ciudad de procedencia
              </li>
            </ul>
          </div>
          <div class="at-control at-fieldset--span-3">
            <label class="--text-small --text-light">Código Postal</label>
            <input class="at-input" 
              required type="text" 
              placeholder="Código Postal" 
              name="codigoPostal"
              #codigoPostal="ngModel" 
              maxlength="10" 
              [(ngModel)]="data.codigoPostal" />
            <ul class="at-control_messages" *ngIf="codigoPostal.invalid && (codigoPostal.touched || codigoPostal.dirty)">
              <li *ngIf="codigoPostal.hasError('required')">
                Ingrese el codigo postal
              </li>
              <li *ngIf="codigoPostal.hasError('maxlength')">
                Maximo 10 caracteres
              </li>
            </ul>
          </div>
        </div>
        <div class="at-fieldset margin-md">
          <div class="at-control">
            <label class="--text-small --text-light">Dirección</label>
            <input class="at-input" 
              required 
              type="text" 
              placeholder="Dirección" 
              name="direccion" 
              #direccion="ngModel"
              [(ngModel)]="data.direccion" />
            <ul class="at-control_messages" *ngIf="direccion.invalid && (direccion.touched || direccion.dirty)">
              <li *ngIf="direccion.hasError('required')">Ingrese la dirección</li>
            </ul>
          </div>
        </div>
        <div class="at-fieldset margin-md">
          <div class="at-control at-fieldset--span-6">
            <label class="--text-small --text-light">Iglesia de procedencia</label>
            <input class="at-input" 
              required 
              type="text" 
              placeholder="Iglesia de procedencia" 
              name="procedencia"
              #procedencia="ngModel" 
              [(ngModel)]="data.procedencia" />
            <ul class="at-control_messages" *ngIf="procedencia.invalid && (procedencia.touched || procedencia.dirty)">
              <li *ngIf="procedencia.hasError('required')">
                Ingrese la iglesia de porcedencia
              </li>
            </ul>
          </div>
          <div class="at-control at-fieldset--span-6">
            <label class="--text-small --text-light">Distrito</label>
            <input class="at-input" 
              type="text" 
              placeholder="Distrito" 
              name="distrito" 
              #distrito="ngModel"
              [(ngModel)]="data.distrito" />
          </div>
        </div>
        <div class="at-fieldset margin-md" *ngIf="emiviId == 2">
          <div class="at-control at-fieldset--span-6">
            <label class="--text-small --text-light">Materias</label>
            <select class="at-input" 
              required 
              name="tipoRegistroId" 
              #tipoRegistroId="ngModel"
              [(ngModel)]="data.materias">
              <option value="0">Selecciona una opción</option>
              <option [value]="item.id" *ngFor="let item of nacimiento">
                {{ item.descripcion }}
              </option>
            </select>
          </div>
        </div>
        <div class="at-item at-item--reverse">
          <button class="at-button at-button--primary at-button--raised" 
            type="submit"
            [disabled]="form.invalid || data.tipoRegistroId == 0 || data.paisId == 0 || data.materias == 0">
            Registrar
          </button>
          <a class="at-button" href="https://ministerioslamision.com/">
            Cancelar
          </a>
        </div>
      </form>
    </div>
    <div *ngIf="terminar" class="margin-md at-card">
      <app-registro-informacion 
        [data]="data"></app-registro-informacion>
      <p>
        Su registro fue exitoso, verifique que su ficha se descargó o llegó a su
        correo
      </p>
      <p>
        Cualquier duda o corrección en la información proprocionada de su
        registro contactar al correo:
        <b>soporte@ministerioslamision.com</b>
      </p>
      <p class="--text-bold">Dios te bendiga de parte de Iglesia La Misión</p>
      <div class="at-item">
        <a class="at-button at-button--primary" 
          href="https://ministerioslamision.com/">Ir a inicio</a>
      </div>
    </div>
  </div>
</div>

<app-confirmar *ngIf="enviar" 
  [data]="data" 
  (cerrar)="cerrar($event)"></app-confirmar>