
  <section class="margin-md at-card ed-content-md">
      <div class="at-item at-item--large">
        <div class="flex">
          <div class="typo-subhead">Check In Emivi</div>
        </div>
      </div>
      <div class="margin-md">
        <form name="form" #form="ngForm" autocomplete="off">
          <div class="at-fieldset margin-md">
            <div class="at-control at-fieldset--span-4">
              <input
                readonly
                class="at-input"
                required
                type="text"
                placeholder="Nombre(s)"
                name="nombre"
                #nombre="ngModel"
                [(ngModel)]="data.registro.nombre"
              />
            </div>
            <div class="at-control at-fieldset--span-4">
              <input
                readonly
                class="at-input"
                required
                type="text"
                placeholder="Apellido Paterno"
                name="apellidoPaterno"
                #apellidoPaterno="ngModel"
                [(ngModel)]="data.registro.apellidoPaterno"
              />
            </div>
            <div class="at-control at-fieldset--span-4">
              <input
                readonly
                class="at-input"
                type="text"
                placeholder="Apellido Materno"
                name="apellidoMaterno"
                #apellidoMaterno="ngModel"
                [(ngModel)]="data.registro.apellidoMaterno"
              />
            </div>
          </div>
          <div class="at-fieldset">
            <div class="at-control at-fieldset--span-4">
              <select
                [disabled]="true"
                class="at-input"
                required
                name="tipoRegistroId"
                #tipoRegistroId="ngModel"
                [(ngModel)]="data.registro.tipoRegistroId"
              >
                <option [value]="item.id" *ngFor="let item of registro">
                  {{item.descripcion}}
                </option>
              </select>
            </div>
            <div class="at-control at-fieldset--span-4">
              <select
                [disabled]="true"
                class="at-input"
                required
                name="paisId"
                #paisId="ngModel"
                [(ngModel)]="data.registro.paisId"
              >
                <option [value]="item.id" *ngFor="let item of paises">
                  {{item.descripcion}}
                </option>
              </select>
            </div>
            <div class="at-control at-fieldset--span-4">
              <input
                readonly
                class="at-input"
                required
                type="text"
                placeholder="Estado"
                name="estado"
                #estado="ngModel"
                [(ngModel)]="data.registro.estado"
              />
            </div>
            <div class="margin-sx"></div>
          </div>
        </form>
      </div>
  </section>
  <section class="margin-md ed-content-md">
      <div class="at-item">
        <div class="typo-title">Asistencias</div>
      </div>
      <div class="at-card margin-md">
        <div class="at-item" *ngFor="let asistencia of data.asistencia">
          <div class="flex">{{asistencia.fecha | date : 'fullDate'}}</div>
          <div class="text-align-right" style="width: 120px">
            <div class="--text-small --text-light">Hora</div>
            <div>{{asistencia.hora}}</div>
          </div>
          <div class="at-icon">
            <i class="fas fa-check-circle --text-success"></i>
          </div>
        </div>
      </div>
  </section>
