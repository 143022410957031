import { Component, OnInit } from '@angular/core';
import { IPaises, IPlantacion } from 'src/app/types';

@Component({
  selector: 'app-registros-plantacion',
  templateUrl: './template.html',
})
export class RegistrosPlantacionComponent implements OnInit {
  data!: IPlantacion
  paises!: IPaises
  terminar: boolean = false
  enviar: boolean = false

  constructor() {
    this.data = {
      paisId: 0,
      nombre: '',
      celular: null,
      email: '',
      direccion: '',
      procedencia: '',
      ciudad: '',
      pastor: '',
      denominacion: '',
      estado: '',
    };

    this.paises = [
      { id: 1, descripcion: 'México' },
      { id: 2, descripcion: 'USA' },
      { id: 3, descripcion: 'Latinoamérica' },
      { id: 4, descripcion: 'Europa' },
      { id: 5, descripcion: 'Asia' },
    ];
  }


  ngOnInit(): void {
    console.info('plantacion')
  }

  guardar(): void {
    this.enviar = true;
  }

  cerrar(event: boolean): void {
    this.enviar = false;

    if (event) {
      this.terminar = true;
    }
  }
}
