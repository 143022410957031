import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RegistroComponent } from './components/registro/registro.component';
import { SesionIniciarComponent } from '../sesion/components/iniciar/iniciar.component';
import { InicioComponent } from './components/inicio/inicio.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { SesionTerminadaComponent } from '../sesion/components/terminada/terminada.component';
import { RegistrosComponent } from './components/registros/registros.component';
import { CheckInComponent } from './components/check-in/component';
import { VerificadorCheckInComponent } from './components/verificadorCheck-In/component';
import { RegistrosPlantacionComponent } from './components/registros-plantacion/component';
import { InformacionComponent } from './components/informacion/component';

const routes: Routes = [
  { path: '', component: InicioComponent },
  { path: 'registro/:id', component: RegistroComponent },
  { path: 'registros-plantacion', component: RegistrosPlantacionComponent },
  { path: 'sesion/login', component: SesionIniciarComponent },
  { path: 'dashboard', component: DashboardComponent },
  { path: 'sesion/terminada', component: SesionTerminadaComponent },
  { path: 'registros', component: RegistrosComponent },
  { path: 'check-in/:registroId/:tipoRegistro', component: CheckInComponent },
  { path: 'verificador-url', component: VerificadorCheckInComponent },
  { path: 'informacion', component: InformacionComponent },
  { path: '**', component: InicioComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
