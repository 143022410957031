import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IRegistro } from '../types';

@Injectable({
  providedIn: 'root',
})
export class RegistroRegistroService {

  constructor(private $http: HttpClient) {
  }

  put(data: IRegistro): Observable<object> {
    return this.$http.put(`/backendemivi/api/registro/registro`, data);
  }
}
