import { Component } from '@angular/core';
import { NavigationEnd, Event, Router } from '@angular/router';
import { IniciarService } from '../sesion/api/iniciar.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent /* implements OnInit */ {
  currentRoute: string;
  rutas = ['/', '/informacion', '/sesion/login', '/sesion/terminada', '/registro/1', '/registro/2', '/registro/3', '/registros-plantacion']
  current: boolean;
  session: boolean = false;

  constructor(private router: Router, private loginService: IniciarService) {
    this.current = false;
    this.currentRoute = ''
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.currentRoute = event.url;
        this.current = this.rutas.some(i => i == this.currentRoute);
        this.validar();
      }
    });
  }

  menu() {
    const sidebar = document.querySelector("[data-sidebar]")

    if (sidebar)
      sidebar.classList.toggle("open")
  }

  validar(): void {
    if (!this.loginService.isAuth() && !this.current) {
      this.router.navigateByUrl('/sesion/login')
        .then(e => e);
    } 
  }

}
