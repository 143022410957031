import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PagoService {

  constructor(private $http: HttpClient) {
  }

  put(data: any, tipo: string): Observable<object> {
    data.tipo = tipo
    return this.$http.put(`/backendemivi/api/registro/pago`, data);
  }
}
