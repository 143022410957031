import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-verificador-check-in',
  templateUrl: './template.html',
})
export class VerificadorCheckInComponent implements OnInit {
  url: string | null = null;

  constructor() { this.url = null }


  ngOnInit(): void { }

  verificar(): void {
    if (this.url) {
      let decodificador = this.url.split('-')
      console.info('url', decodificador)
      let newUrl = `https://workhome.ministerioslamision.com/check-in/${decodificador[0]}/${decodificador[1]}`
      //let newUrl = `http://localhost:4200/check-in/${decodificador[0]}/${decodificador[1]}`
      console.info('new url', newUrl)
      window.open(newUrl, '_blank');
      this.url = null
    }
  }
}
