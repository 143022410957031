import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IPlantacion } from '../types';

@Injectable({
  providedIn: 'root',
})
export class RegistrosPlantacionService {

  constructor(private $http: HttpClient) {
  }

  get(fechaInicial: string, fechaFinal: string): Observable<IPlantacion[]> {
    return this.$http.get<IPlantacion[]>(`/backendemivi/api/registro-plantacion`, { params: { fechaInicial, fechaFinal } });
  }


  post(data: IPlantacion): Observable<Blob> {
    return this.$http.post<Blob>(`/backendemivi/api/registro-plantacion`, data, { responseType: 'blob' as 'json' });
  }

  put(data: any): Observable<object> {
    return this.$http.put(`/backendemivi/api/registro-plantacion`, data);
  }
}
