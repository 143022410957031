import { Component, Input, OnInit } from "@angular/core";
import { IRecaudado } from "src/app/types";

@Component({
  selector: 'app-dashboard-recaudado',
  templateUrl: './recaudado.component.html',
})
export class DashboardRecaudadoComponent implements OnInit {
  @Input() data!: IRecaudado
  constructor() {
  }

  ngOnInit(): void {
  }
}