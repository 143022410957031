import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler, HttpHeaderResponse,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AutorizacionService implements HttpInterceptor {

  constructor(private router: Router) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let request = null;

    const ngToken = localStorage.getItem('ng-token') || '';
    const headers = new HttpHeaders({
      Authorization: `Bearer ${ngToken}`,
    });

    request = req.clone({
      headers,
    });

    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          if (event.status === 200) {
          }
        }
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          this.router.navigateByUrl('/sesion/iniciar')
            .then(resp => (console.log(resp, 'que pedotl')));
        }

        return throwError(error);
      }));

    //   finalize(() => {
    //     // al finalizar de armar la petición HTTP
    //   }));

  }
}
