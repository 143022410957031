import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RegistrosPlantacionService } from 'src/app/api/registro-plantacion.service';
import { IPlantacion } from 'src/app/types';
import * as saveAs from 'file-saver';

@Component({
  selector: 'app-registros-plantacion-confirmar',
  templateUrl: './template.html',
})
export class RegistrosPlantacionConfirmarComponent implements OnInit {
  @Input() data!: IPlantacion;
  procesando: boolean = false;
  exito: boolean = false;
  error: Error | undefined;
  @Output() cerrar = new EventEmitter<boolean>();

  constructor(private registroPlantacionService: RegistrosPlantacionService) { }

  ngOnInit(): void { }

  confirmar(): void {
    if (this.procesando) {
      return;
    }

    this.procesando = true;
    this.error = undefined;

    this.registroPlantacionService.post(this.data)
      .subscribe({
        next: (data) => {
          const blob = new Blob([data], { type: 'application/pdf' });
          const name = 'ficha.pdf';
          saveAs(blob, name);
          this.exito = true;
        },
        error: (error) => { 
          this.procesando = false; 
          this.error = error.error; 
        },
        complete: () => this.procesando = false,
      })
  }

  aceptar(): void {
    this.cerrar.emit(this.exito);
  }
}
