<section>
  <div class="at-item at-item--large">
    <div class="flex">
      <div class="typo-subhead">Confirmar y verificar su información</div>
    </div>
  </div>
  <div class="margin-md">
    <form name="form" #form="ngForm" autocomplete="off">
      <div class="at-fieldset margin-md">
        <div class="at-control at-fieldset--span-6">
          <input
            readonly
            class="at-input"
            required
            type="text"
            placeholder="Nombre(s)"
            name="nombre"
            #nombre="ngModel"
            [(ngModel)]="data.nombre"
          />
        </div>
        <div class="at-control at-fieldset--span-3">
          <input
            readonly
            class="at-input"
            required
            type="text"
            placeholder="Apellido Paterno"
            name="apellidoPaterno"
            #apellidoPaterno="ngModel"
            [(ngModel)]="data.apellidoPaterno"
          />
        </div>
        <div class="at-control at-fieldset--span-3">
          <input
            readonly
            class="at-input"
            type="text"
            placeholder="Apellido Materno"
            name="apellidoMaterno"
            #apellidoMaterno="ngModel"
            [(ngModel)]="data.apellidoMaterno"
          />
        </div>
      </div>
      <div class="at-fieldset">
        <div class="at-control at-fieldset--span-6">
          <input
            readonly
            class="at-input"
            required
            placeholder="email@ejemplo.com"
            pattern="[a-zA-Z0-9_]+([.][a-zA-Z0-9_]+)*@[a-zA-Z0-9_]+([.][a-zA-Z0-9_]+)*[.][a-zA-Z]{1,5}"
            type="text"
            name="email"
            #email="ngModel"
            [(ngModel)]="data.email"
          />
        </div>
        <div class="at-control at-fieldset--span-3">
          <input
            readonly
            class="at-input"
            required
            placeholder="Celular"
            type="number"
            maxlength="20"
            name="celular"
            #celular="ngModel"
            [(ngModel)]="data.celular"
          />
        </div>
        <div class="at-control at-fieldset--span-3">
          <select
            [disabled]="true"
            class="at-input"
            required
            name="tipoRegistroId"
            #tipoRegistroId="ngModel"
            [(ngModel)]="data.tipoRegistroId"
          >
            <option [value]="item.id" *ngFor="let item of registro">
              {{item.descripcion}}
            </option>
          </select>
        </div>
      </div>
      <div class="at-fieldset margin-md">
        <div class="at-control at-fieldset--span-3">
          <select
            [disabled]="true"
            class="at-input"
            required
            name="paisId"
            #paisId="ngModel"
            [(ngModel)]="data.paisId"
          >
            <option [value]="item.id" *ngFor="let item of paises">
              {{item.descripcion}}
            </option>
          </select>
        </div>
        <div class="at-control at-fieldset--span-3">
          <input
            readonly
            class="at-input"
            required
            type="text"
            placeholder="Estado"
            name="estado"
            #estado="ngModel"
            [(ngModel)]="data.estado"
          />
        </div>
        <div class="at-control at-fieldset--span-3">
          <input
            readonly
            class="at-input"
            required
            type="text"
            placeholder="Ciudad"
            name="ciudad"
            #ciudad="ngModel"
            [(ngModel)]="data.ciudad"
          />
        </div>
        <div class="at-control at-fieldset--span-3">
          <input
            readonly
            class="at-input"
            required
            type="text"
            placeholder="Código Postal"
            name="codigoPostal"
            #codigoPostal="ngModel"
            maxlength="10"
            [(ngModel)]="data.codigoPostal"
          />
        </div>
      </div>
      <div class="at-fieldset margin-md">
        <div class="at-control">
          <input
            readonly
            class="at-input"
            required
            type="text"
            placeholder="Dirección"
            name="direccion"
            #direccion="ngModel"
            [(ngModel)]="data.direccion"
          />
        </div>
      </div>
      <div class="at-fieldset margin-md">
        <div class="at-control at-fieldset--span-6">
          <input
            readonly
            class="at-input"
            required
            type="text"
            placeholder="Iglesia de procedencia"
            name="procedencia"
            #procedencia="ngModel"
            [(ngModel)]="data.procedencia"
          />
        </div>
        <div class="at-control at-fieldset--span-6">
          <input
            readonly
            class="at-input"
            required
            type="text"
            placeholder="Distrito"
            name="distrito"
            #distrito="ngModel"
            [(ngModel)]="data.distrito"
          />
        </div>
      </div>
      <div class="at-fieldset margin-md" *ngIf="data.emiviId == 2">
        <div class="at-control at-fieldset--span-6">
          <label class="--text-small --text-light">Materias</label>
          <select class="at-input" 
            required 
            name="tipoRegistroId" 
            #tipoRegistroId="ngModel"
            [(ngModel)]="data.materias">
            <option value="0">Selecciona una opción</option>
            <option [value]="item.id" *ngFor="let item of nacimiento">
              {{ item.descripcion }}
            </option>
          </select>
        </div>
      </div>
    </form>
  </div>
</section>
