<div class="at-modal">
  <div class="at-dialog" max-width="sm">
    <section class="padding-md">
      <div class="typo-title at-item">
        {{titulo}}
      </div>

      <div class="margin-ms" *ngIf="procesando">
        <div class="at-item at-item--large">
          <i class="fas fa-atom ed-rodar" style="font-size: 1.5rem"></i>&nbsp;Cargando información ....
        </div>
      </div>

      <div class="margin-md tag-error" *ngIf="error">
        Error: {{error}}
      </div>

      <div class="margin-ms" *ngIf="exito">
        <i class="fa fa-fw fa-check-circle --text-success"></i>
        <span>Se han descargado el documento exitosamente</span>
      </div>

      <div class="at-item at-item--reverse" *ngIf="!procesando">
        <button class="at-button at-button--primary" 
          (click)="onDestroy()">
          Aceptar
        </button>
      </div>
    </section>
  </div>
</div>