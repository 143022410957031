<div class="ed-content">
  <div class="padding-md">
    <h1 class="typo-display margin-lg">Dashboard</h1>
  </div>
  <div class="ed-container-grid" 
    *ngIf="dashboard | async as data; else loading;">
    <app-dashboard-registros
      class="ed-registro"
      [data]="data.registros"></app-dashboard-registros>
    <app-dashboard-expiracion 
      class="ed-expiracion"></app-dashboard-expiracion>
    <app-dashboard-recaudado
      *ngIf="permisos == 'eplantillas'"
      class="ed-recaudado"
      [data]="data.recaudado"></app-dashboard-recaudado>
    <app-dashboard-historial
      class="ed-historial"
      [data]="data.historial"></app-dashboard-historial>

  </div>
  <ng-template #loading>
    <div class="at-item at-item--large">
      <i class="fas fa-atom ed-rodar" style="font-size: 1.5rem"></i>&nbsp;Cargando información ....
    </div>
  </ng-template>
</div>
