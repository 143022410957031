import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IRegistro } from '../../../../types';
import { QrService } from '../../../../api/qr.service';

@Component({
  selector: 'app-registros-editar-qr',
  templateUrl: './qr.component.html',
  styleUrls: ['./qr.component.scss'],
})
export class QrComponent implements OnInit {
  @Input() data!: IRegistro;
  @Input() tipo!: string;
  procesando: boolean;
  exito: boolean;
  error: any;
  @Output() cerrar = new EventEmitter<boolean>();

  constructor(private qrService: QrService) {
    this.procesando = false;
    this.exito = false;
    this.error = null;
  }

  ngOnInit(): void {
  }

  aceptar(): void {
    if (!this.exito) {
      this.cerrar.emit(false);
    } else {
      this.cerrar.emit(true);
    }
  }

  confirmar(): void {
    if (this.procesando) {
      return;
    }

    this.procesando = true;
    this.error = null;

    this.qrService.post(this.data, this.tipo).subscribe({
      next: () => { this.exito = true },
      error: (err) => { this.error = err },
    }).add(() => { this.procesando = false })
  }

}
