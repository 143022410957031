<div class="at-item padding-md">
    <div class="flex">
      <div class="--text-light typo-subhead">Recaudado</div>
    </div>
    <a><i class="pi pi-external-link"></i></a>
  </div>
  <div class="at-item margin-ss padding-md">
    <div class="flex">
      <div class="--text-bold">Efectivo</div>
      <div class="--text-small --text-light">${{data.efectivo}}</div>
    </div>
    <div class="--text-light">Iglesia</div>
    <button class="at-icon" type="button">
      <i class="pi pi-trash --text-error"></i>
    </button>
  </div>
  <div class="at-item margin-ss padding-md">
    <div class="flex">
      <div class="--text-bold">Tranferencias</div>
      <div class="--text-small --text-light">${{data.tranferencia}}</div>
    </div>
    <div class="--text-light">Banorte ~ 0613</div>
    <button class="at-icon" type="button">
      <i class="pi pi-trash --text-error"></i>
    </button>
  </div>