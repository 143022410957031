import { Component, Input, OnInit } from '@angular/core';
import { IPaises, IRegistro } from '../../../types';

@Component({
  selector: 'app-registro-informacion',
  templateUrl: './template.html',
})
export class RegistroInformacionComponent implements OnInit {
  @Input() data: IRegistro;
  paises: IPaises;
  registro: IPaises;
  nacimiento: IPaises;

  constructor() {
    this.data = {
      nombres: '',
      nombre: '',
      apellidoPaterno: '',
      apellidoMaterno: '',
      email: '',
      celular: '',
      procedencia: 'N/A',
      distrito: '',
      paisId: 1,
      estado: '',
      ciudad: '',
      direccion: '',
      codigoPostal: '',
      tipoRegistroId: 1,
      emiviId: 0,
      materias: 0,
    };
    this.paises = [
      { id: 1, descripcion: 'México' },
      { id: 2, descripcion: 'USA' },
      { id: 3, descripcion: 'Latinoamérica' },
      { id: 4, descripcion: 'Europa' },
      { id: 5, descripcion: 'Asia' },
    ];
    this.registro = [
      { id: 1, descripcion: 'Obispo' },
      { id: 2, descripcion: 'Pastor' },
      { id: 3, descripcion: 'Ministro' },
      { id: 4, descripcion: 'Diacono' },
      { id: 5, descripcion: 'Lider' },
      { id: 6, descripcion: 'Otros' },
    ];
    this.nacimiento = [
      { id: 1, descripcion: 'Sin materias' },
      { id: 2, descripcion: 'Validar materias' },
    ];
  }

  ngOnInit(): void {
  }
}
