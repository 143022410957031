<div class="ed-content">
  <div class="at-header-center">
    <div class="toolbar-container">
      <div class="toolbar-container-header">
        <div class="toolbar-header">
          <a class="typo-display">Registro EMIVI Plantación 2023</a>
        </div>
      </div>
    </div>
  </div>

  <div class="at-header-center" style="max-width: 700px">
    <div class="at-item padding-md">
      <h1 class="typo-display margin-lg" *ngIf="terminar">Registro Exitoso</h1>
    </div>
    <div class="at-item at-item--large" *ngIf="terminar">
      <div class="flex">
        <div class="typo-subhead">Confirmar y verificar su información</div>
      </div>
    </div>
    <div class="margin-md">
      <form name="form" 
        #form="ngForm" 
        autocomplete="off" 
        (ngSubmit)="guardar()">
        <div class="at-fieldset margin-md">
          <div class="at-control at-fieldset--span-12">
            <label class="--text-small --text-light">Nombre</label>
            <input class="at-input" 
              required type="text" 
              placeholder="Nombre(s)" 
              name="nombre" 
              #nombre="ngModel"
              [(ngModel)]="data.nombre" />
            <ul class="at-control_messages" *ngIf="nombre.errors && nombre.touched">
              <li *ngIf="nombre.errors.required">Ingrese un nombre</li>
            </ul>
          </div>
        </div>
        <div class="at-fieldset">
          <div class="at-control at-fieldset--span-6">
            <label class="--text-small --text-light">Email</label>
            <input class="at-input" 
              required 
              placeholder="email@ejemplo.com"
              pattern="[a-zA-Z0-9_]+([.][a-zA-Z0-9_]+)*@[a-zA-Z0-9_]+([.][a-zA-Z0-9_]+)*[.][a-zA-Z]{1,5}" type="text"
              name="email" 
              #email="ngModel" 
              [(ngModel)]="data.email" />
            <ul class="at-control_messages" *ngIf="email.errors && email.touched">
              <li *ngIf="email.errors.required">Email requerido</li>
              <li *ngIf="email.errors.pattern">Email en formato incorrecto</li>
            </ul>
          </div>
          <div class="at-control at-fieldset--span-6">
            <label class="--text-small --text-light">Celular</label>
            <input class="at-input" 
              required 
              placeholder="Celular" 
              type="number" 
              maxlength="20" 
              name="celular"
              #celular="ngModel" 
              [(ngModel)]="data.celular" />
            <ul class="at-control_messages" *ngIf="celular.errors && celular.touched">
              <li *ngIf="celular.errors.required">Ingrese un # celular</li>
            </ul>
          </div>
        </div>
        <div class="at-fieldset margin-md">
          <div class="at-control at-fieldset--span-4">
            <label class="--text-small --text-light">País</label>
            <select class="at-input" 
              required 
              name="paisId" 
              #paisId="ngModel" 
              [(ngModel)]="data.paisId">
              <option value="0">Selecciona una opción</option>
              <option [value]="item.id" *ngFor="let item of paises">
                {{ item.descripcion }}
              </option>
            </select>
          </div>
          <div class="at-control at-fieldset--span-4">
            <label class="--text-small --text-light">Estado</label>
            <input class="at-input" 
              required 
              type="text" 
              placeholder="Estado" 
              name="estado" 
              #estado="ngModel"
              [(ngModel)]="data.estado" />
            <ul class="at-control_messages" *ngIf="estado.errors && estado.touched">
              <li *ngIf="estado.errors.required">
                Ingrese el estado de procedencia
              </li>
            </ul>
          </div>
          <div class="at-control at-fieldset--span-4">
            <label class="--text-small --text-light">Ciudad</label>
            <input class="at-input" 
              required 
              type="text" 
              placeholder="Ciudad" 
              name="ciudad" 
              #ciudad="ngModel"
              [(ngModel)]="data.ciudad" />
            <ul class="at-control_messages" *ngIf="ciudad.errors && ciudad.touched">
              <li *ngIf="ciudad.errors.required">
                Ingrese la ciudad de procedencia
              </li>
            </ul>
          </div>
        </div>
        <div class="at-fieldset margin-md">
          <div class="at-control">
            <label class="--text-small --text-light">Dirección</label>
            <input class="at-input" 
              required 
              type="text" 
              placeholder="Dirección" 
              name="direccion" 
              #direccion="ngModel"
              [(ngModel)]="data.direccion" />
            <ul class="at-control_messages" *ngIf="direccion.errors && direccion.touched">
              <li *ngIf="direccion.errors.required">Ingrese la dirección</li>
            </ul>
          </div>
        </div>
        <div class="at-fieldset margin-md">
          <div class="at-control at-fieldset--span-12">
            <label class="--text-small --text-light">Iglesia de procedencia</label>
            <input class="at-input" 
              required 
              type="text" 
              placeholder="Iglesia de procedencia" 
              name="procedencia"
              #procedencia="ngModel" 
              [(ngModel)]="data.procedencia" />
            <ul class="at-control_messages" *ngIf="procedencia.errors && procedencia.touched">
              <li *ngIf="procedencia.errors.required">
                Ingrese la iglesia de porcedencia
              </li>
            </ul>
          </div>
        </div>
        <div class="at-item at-item--reverse" *ngIf="!terminar">
          <a class="at-button" href="https://ministerioslamision.com/">
            Cancelar
          </a>
          <button class="at-button at-button--primary at-button--raised" type="submit"
            [disabled]="form.invalid || data.paisId == 0">
            Registrar
          </button>
        </div>
      </form>
    </div>
    <div *ngIf="terminar" class="margin-md">
      <!-- <app-registro-informacion [data]="data"></app-registro-informacion> -->
      <p>
        Su registro fue exitoso, verifique que su ficha se descargó o llegó a su
        correo
      </p>
      <p>
        Cualquier duda o corrección en la información proprocionada de su
        registro contactar al correo:
        <b>soporte@ministerioslamision.com</b>
      </p>
      <p class="--text-bold">Dios te bendiga de parte de Iglesia La Misión</p>
      <div class="at-item">
        <a class="at-button at-button--primary" href="https://ministerioslamision.com/">Ir a inicio</a>
      </div>
    </div>
  </div>
</div>

<app-registros-plantacion-confirmar *ngIf="enviar" 
  [data]="data" 
  (cerrar)="cerrar($event)"></app-registros-plantacion-confirmar>