import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-registros-lista',
  templateUrl: './lista.component.html',
  styleUrls: ['./lista.component.scss'],
})
export class ListaComponent implements OnInit {
  @Input() data!: any;
  @Input() tipo!: string;
  ver: boolean;

  constructor() {
    this.ver = false;
  }

  ngOnInit(): void {
  }

  cerrar(event: boolean): void {
    this.ver = false;
  }

}
