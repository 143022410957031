import { Component, Input, OnInit } from "@angular/core";
import { IHistorial } from "src/app/types";

@Component({
  selector: 'app-dashboard-historial',
  templateUrl: './historial.component.html',
})
export class DashboardHistorialComponent implements OnInit {
  @Input() data!: IHistorial[]
  constructor() {
  }

  ngOnInit(): void {
  }
}