import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IDashboard } from '../types';

@Injectable({
  providedIn: 'root',
})
export class DashboardService {

  constructor(private $http: HttpClient) {
  }

  get(): Observable<IDashboard> {
    return this.$http.get<IDashboard>(`/backendemivi/api/dashboard`);
  }
}
