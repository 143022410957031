<div class="at-item padding-md">
    <div class="flex">
      <div class="--text-light typo-subhead">Registros</div>
    </div>
  </div>
  <div class="at-item padding-md">
    <div class="flex">
      <div class="--text-light --text-bold">Total</div>
      <div>{{data.total}}</div>
    </div>
    <div class="text-align-right" style="width: 120px">
      <div class="--text-small --text-light">Obispos</div>
      <div>{{data.obispo}}</div>
    </div>
    <div class="text-align-right" style="width: 120px">
      <div class="--text-small --text-light">Pastores</div>
      <div>{{data.pastor}}</div>
    </div>
    <div class="text-align-right" style="width: 120px">
      <div class="--text-small --text-light">Ministros</div>
      <div>{{data.ministro}}</div>
    </div>
    <div class="text-align-right" style="width: 120px">
      <div class="--text-small --text-light">Diaconos</div>
      <div>{{data.diacono}}</div>
    </div>
    <div class="text-align-right" style="width: 120px">
      <div class="--text-small --text-light">Lideres</div>
      <div>{{data.lider}}</div>
    </div>
    <div class="text-align-right" style="width: 120px">
      <div class="--text-small --text-light">Otros</div>
      <div>{{data.otro}}</div>
    </div>
  </div>