import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-informacion',
  templateUrl: './template.html',
  styleUrls: ['./style.scss'],
})
export class InformacionComponent implements OnInit {
  constructor(private router: Router) { }

  ngOnInit(): void { }

  ir(url: string, emiviId: number): void {
    this.router.navigate([url, emiviId ]);
  }
}
