import { Component, OnInit } from "@angular/core";
import { formatearFecha } from "../../registros/registros.component";

@Component({
  selector: 'app-dashboard-expiracion',
  templateUrl: './expiracion.component.html',
})
export class DashboardExpiracionComponent implements OnInit {
  data = {
    fecha: '2024-02-01',
    fecha2: '2024-02-02',
    fecha3: '2024-02-03',
  }

  date: string = formatearFecha(new Date());

  constructor() {
  }

  ngOnInit(): void {
  }
}