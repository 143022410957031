import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IRegistro } from '../types';

@Injectable({
  providedIn: 'root',
})
export class QrService {

  constructor(private $http: HttpClient) {
  }


  post(data: IRegistro, tipo: string): Observable<object> {
    data.tipo = tipo
    return this.$http.post(`/backendemivi/api/registros/qr`, data);
  }
}
