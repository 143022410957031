import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { RegistroComponent } from './components/registro/registro.component';
import { ConfirmarComponent } from './components/registro/confirmar/confirmar.component';
import { FormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AppModuleSesion } from '../sesion/app.module';
import { InicioComponent } from './components/inicio/inicio.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { SesionUsuarioComponent } from '../sesion/components/usuario/usuario.component';
import { AutorizacionService } from './interceptor/autorizacion.service';
import { RegistrosComponent } from './components/registros/registros.component';
import { ListaComponent } from './components/registros/lista/lista.component';
import { EditarComponent } from './components/registros/editar/editar.component';
import { PagoComponent } from './components/registros/editar/pago/pago.component';
import { QrComponent } from './components/registros/editar/qr/qr.component';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { RegistroInformacionComponent } from './components/registro/informacion/component';
import { CheckInComponent } from './components/check-in/component';
import { AwaitComponent } from './components/await/component';
import { VerificadorCheckInComponent } from './components/verificadorCheck-In/component';
import { DashboardRegistrosComponent } from './components/dashboard/regsitros/registros.component';
import { DashboardExpiracionComponent } from './components/dashboard/expiracion/expiracion.component';
import { DashboardRecaudadoComponent } from './components/dashboard/recaudado/recaudado.component';
import { DashboardHistorialComponent } from './components/dashboard/historial/historial.component';
import { RegistrosPlantacionComponent } from './components/registros-plantacion/component';
import { RegistrosPlantacionConfirmarComponent } from './components/registros-plantacion/confirmar/component';

import localeEs from '@angular/common/locales/es';
import { registerLocaleData } from '@angular/common';
import { SesionHeaderComponent } from '../sesion/components/interface/header/header.component';
import { SesionInterfaceModalDescargaComponent } from '../sesion/components/interface/modal-descarga/modal-descarga';
registerLocaleData(localeEs, 'es');


@NgModule({
  declarations: [
    AppComponent,
    RegistroComponent,
    ConfirmarComponent,
    InicioComponent,
    DashboardComponent,
    SesionUsuarioComponent,
    RegistrosComponent,
    ListaComponent,
    EditarComponent,
    PagoComponent,
    QrComponent,
    RegistroInformacionComponent,
    CheckInComponent,
    VerificadorCheckInComponent,
    AwaitComponent,
    SesionHeaderComponent,
    DashboardRegistrosComponent,
    DashboardExpiracionComponent,
    DashboardRecaudadoComponent,
    DashboardHistorialComponent,
    RegistrosPlantacionComponent,
    RegistrosPlantacionConfirmarComponent,
    SesionInterfaceModalDescargaComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    AppModuleSesion,
    Ng2SearchPipeModule,
  ],
  providers: [{
    provide: HTTP_INTERCEPTORS,
    useClass: AutorizacionService,
    multi: true,
  }, {
    provide: LOCALE_ID, useValue: 'es',
  },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {
}
